#dwv {
  height: 90%; }

.button-row {
  text-align: center;
}

/* Layers */
.layerContainer {
    position: relative; padding: 0;
    margin: auto; text-align: center; }
.imageLayer {
    position: absolute;
    left: 0px; }
.drawDiv {
    position: absolute; pointer-events: none; }

/* drag&drop */
.dropBox {
    border: 5px dashed #e8eaf6; /*indigo:light*/
    margin: auto;
    text-align: center; vertical-align: middle; }
.dropBox.hover {
    border: 5px dashed #3f51b5; /*indigo*/ }
