html,
body {
  margin: 0;
  /* background-Color:'#e1eadc', */
  /* background-image: linear-gradient(
    to right top,
    #cbe3d1,
    #cbe3d1,
    #cbe3d1,
    #cbe3d1,
    #cbe3d1
  ); */
  background: url(./components/images/bgmain.jpg) no-repeat center center fixed;
  background-size: cover;
}
.tbl {
  background-color: white;
}
#App {
  font-family: sans-serif;
  height: 100vh;
}

#page-wrap {
  text-align: center;
  overflow: auto;
  /* background-color: white; */
}

.sticky {
  position: fixed;
  position: -webkit-sticky;
  top: 70px;
  width: 100%;
  z-index: 2;
  /* overflow:scroll; */
  /* margin-top: 50px; */
  /* background-color:#eeeeee; */
  padding: 100px;
  height: calc(100vh - 70px);
  font-size: 20px;
}

.study {
  top: 0;
  margin: auto;
  width: 100%;
  /* background-color:white; */
  padding: 70px;
}

.study-info {
  margin: 20px;
  font-size: 14px;
}

.sticky-offset {
  top: 56px;
}

.vertical-center {
  margin: 20px;
  float: none;
}
